import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Order, Party, OrderHistoryComponent, OrderUIConfiguration, UIConfigurationService } from 'reg-hub-common';
import { Constants } from '../../../../src/constants';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.css']
})
export class OrderDetailsComponent implements OnInit {
  @Input() order!: Order
  orderTypeName: string = ""

  debtors!: Observable<Party[]>;
  securedParties!: Observable<Party[]>;
  dealers!: Observable<Party[]>;
  uiConfiguration: OrderUIConfiguration | null = null;

  debtorsTitle: string = "Debtors";
  securedPartiesTitle: string = "Secured Parties";
  dealersTitle: string = "Dealers";
  showEstate: boolean = false;

  loading: boolean = true;

  constructor(private dialog: MatDialog,
    private uiConfigurationService: UIConfigurationService) { }

  ngOnInit(): void {
    this.orderTypeName = this.order.orderType.name;
    this.debtors = of(this.getDebtors());
    this.securedParties = of(this.getSecuredParties());
    this.dealers = of(this.getDealers());
    this.getUIConfiguration(this.order);
  }

  getUIConfiguration(order: Order) {
    this.uiConfigurationService.getOrderUIConfiguration(order).subscribe(config => {
      this.uiConfiguration = config;
      this.loading = false;
    });
  }

  openOrderHistory() {
    this.dialog.open(OrderHistoryComponent, { data: this.order });
  }

  isSearchOrder(): boolean {
    return this.order.orderTypeID.toString() == "AssetSearch"
      || this.order.orderTypeID.toString() == "DebtorSearch"
      || this.order.orderTypeID.toString() == "CertifiedDebtorSearch";
  }

  isDischarge(): boolean {
    return this.order.orderTypeID.toString() == "Discharge"
      || this.order.orderTypeID.toString() == "StandaloneDischarge";
  }

  showAdditionalInformation(): boolean {
    return (this.order.lien != null &&
      (this.order.jurisdiction == 'AB' ||
        this.order.jurisdiction == 'MB' ||
        this.order.jurisdiction == 'QC' ||
        this.order.jurisdiction == 'NB' ||
        this.order.jurisdiction == 'NS' ||
        this.order.jurisdiction == 'PE' ||
        this.order.jurisdiction == 'NL' ||
        this.order.jurisdiction == 'YT' ||
        this.order.jurisdiction == 'NT' ||
        this.order.jurisdiction == 'NU')
    );
  }

  getDebtors(): Party[] {
    return this.order.parties?.filter((party) => Constants.isDebtor(party.partyTypeID)) ?? [];
  }

  getSecuredParties(): Party[] {
    return this.order.parties?.filter((party) => Constants.isSecuredParty(party.partyTypeID)) ?? [];
  }

  getDealers(): Party[] {
    return this.order.parties?.filter((party) => Constants.isDealer(party.partyTypeID)) ?? [];
  }
}
