import { Component, ViewChild } from '@angular/core';
import { Amendment, OrderManagerService, ValidationProblem, ValidationService } from 'reg-hub-common';
import { BaseLienDetailsComponent } from '../base-lien-details.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { AmendmentDetailsComponent } from '../../amendment-details/amendment-details.component';

@Component({
  selector: 'app-on-lien-details',
  templateUrl: './on-lien-details.component.html',
  styleUrls: ['./on-lien-details.component.css']
})
export class ONLienDetailsComponent extends BaseLienDetailsComponent {
  cautionFilingError$ = new BehaviorSubject<string | null | undefined>(null);
  maturityDateError$ = new BehaviorSubject<string | null | undefined>(null);

  constructor(
    formBuilder: FormBuilder,
    orderManager: OrderManagerService,
    validationService: ValidationService) {
    super(formBuilder, orderManager, validationService);
  }

  public override pushErrors(errors: ValidationProblem[] | undefined): void {
    super.pushErrors(errors);

    this.cautionFilingError$.next(errors?.filter(error => error.path.includes('cautionfiling'))?.at(0)?.userFriendlyMessage);
    this.maturityDateError$.next(errors?.filter(error => error.path.includes('/maturitydate'))?.at(0)?.userFriendlyMessage);
  }

  protected override buildForm(): FormGroup {
    var form = this.formBuilder.group({ }) as FormGroup;

    if(this.uiConfiguration.lienDetailsConfiguration?.showAmount) {
      form.addControl('amount', this.formBuilder.control(this.order.lien?.amount));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showCautionFiling) {
      form.addControl('cautionFiling', this.formBuilder.control(this.order.lien?.cautionFiling ?? false));
    }

    if(this.uiConfiguration.lienDetailsConfiguration?.showMaturityDate) {
      form.addControl('maturityDate', this.formBuilder.control(this.order.lien?.maturityDate));
      form.addControl('noFixedMaturityDate', this.formBuilder.control(this.order.lien?.noFixedMaturityDate ?? false));

      form.get('noFixedMaturityDate')?.valueChanges.subscribe(value => {
        if(value) {
          form.get('maturityDate')?.setValue(null, { emitEvent: false });
        }
      })
    }

    return form;
  }
}