import { Component, Input,OnDestroy,OnInit,ViewChild } from '@angular/core';
import { JurisdictionSelectionComponent, Order, OrderManagerService, eOrderType, eOrderTypeGroup } from 'reg-hub-common';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-base-order-information-review',
  templateUrl: './base-order-information-review.component.html',
  styleUrls: ['./base-order-information-review.component.css']
})
export class BaseOrderInformationReviewComponent implements OnInit, OnDestroy {
  @Input() orderTypeGroupID!: eOrderTypeGroup;
  @ViewChild(JurisdictionSelectionComponent) jurisdictionSelectionComponent!: JurisdictionSelectionComponent;

  private unsubscribe$ = new Subject<void>();

  order: Order = { referenceNumber: "",
    country: "CA",
    jurisdiction: "",
    orderTypeID: eOrderType.notSet
  } as Order;

  constructor(
    private orderManager: OrderManagerService
  ) { }

  ngOnInit(): void {
    this.orderManager.currentOrder
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(order => this.order = order);
  }

  ngOnDestroy(): void {
    // Signal all subscriptions to complete
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
