import { Component, Input } from '@angular/core';
import { Order, OrderFee, OrderFeeRepositoryService } from 'reg-hub-common';

@Component({
  selector: 'app-order-fees',
  templateUrl: './order-fees.component.html',
  styleUrls: ['./order-fees.component.css']
})
export class OrderFeesComponent {
  @Input() order?: Order | null;
  isComplete: boolean = false;
  orderFees: OrderFee[] = [];
  totalCost: number = 0;
  emptyGuid = '00000000-0000-0000-0000-000000000000';
  displayedColumns: string[] = ['description', 'amount', 'tax'];

  constructor(private orderFeesRepo: OrderFeeRepositoryService) { }

  ngOnInit(): void {
    if (this.order != null && this.order.id != undefined) {
      this.isComplete = this.order?.orderStatusTypeID == "Complete" || this.order?.orderStatusTypeID == "Cancelled";

      this.orderFeesRepo.getOrderFees(this.order!.id)
        .subscribe(fees => {
          this.orderFees = fees;

          if (!this.isComplete) {
            //Fetch expected Fees
            this.orderFeesRepo.getExpectedOrderFees(this.order!.id)
              .subscribe(expecteedfees => {
                this.orderFees = this.orderFees.concat(expecteedfees);
                this.calculateTotal();
              });
          }
          else
          {
            //If complete - no need to fetch expected fees
            this.calculateTotal();
          }
        });
    }
  }

  calculateTotal(): void {
    this.totalCost = this.orderFees.reduce((acc, fee) => acc + fee.amount + fee.gst + fee.pst + fee.hst, 0);
  }
}
